<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="域名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="domain">
            <a-input v-model="form.domain" placeholder="请输入域名..." />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="esHostName">
          <a-form-model-item label="ES主机名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts_name">
            <a-input v-model="form.es_hosts_name" placeholder="请输入ES主机名称..." />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="语言" :labelCol='labelcol' :wrapperCol="wrappercol" prop="language_id">
            <a-select mode="multiple" v-model="form.language_id" placeholder="请选择语言..." :options='languageList'>
            </a-select>
          </a-form-model-item>
        </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
            <a-form-model-item style="text-align:right;">

              <a-button type="primary" @click="search">
                搜索
              </a-button>
              <a-button @click="reset('ruleForm')">重置</a-button>

            </a-form-model-item>
          </a-col>
      </a-form-model>
    </div>
    <div class="mainContent" style="display: flex;justify-content:space-between;">
      <div>
        <a-button type="primary" @click="onSetUp" :disabled="!selectedRowKeys.length > 0">
          设置邮箱
        </a-button>
        <a-button type="primary" @click="updataWeb" style="margin-left:10px" :disabled="!selectedRowKeys.length > 0">
          更新网站
        </a-button>
        <a-button type="primary" @click="setPayment" style="margin-left:10px" :disabled="!selectedRowKeys.length > 0">
          设置支付
        </a-button>
        <a-button type="primary" @click="setImg" style="margin-left:10px" :disabled="!selectedRowKeys.length > 0">
          设置图片
        </a-button>
        <a-button type="primary" @click="setFee" style="margin-left:10px" :disabled="!selectedRowKeys.length > 0">
          设置运费
        </a-button>
        <a-button type="primary" @click="setDmca" style="margin-left:10px" :disabled="!selectedRowKeys.length > 0">
          设置DMCA
        </a-button>
        <a-button type="primary" @click="setAD" style="margin-left:10px" :disabled="!selectedRowKeys.length > 0">
          设置广告语
        </a-button>
        <a-button type="primary" @click="setBrand" style="margin-left:10px" :disabled="!selectedRowKeys.length > 0">
          设置品牌
        </a-button>
      </div>
      <a-button type="primary" @click="onAdd">
        添加网站
      </a-button>
    </div>
    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :scroll="{ x: true }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.sites_id"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >
        <!-- 状态单元格 -->
        <!-- <template slot="is_delete" slot-scope="data,record">
          <a-tag :color="record.is_delete=='0'?'green':'red'">
              {{record.is_delete|statusFilter}}
          </a-tag>
        </template> -->
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

              <span>

                <a-button size="small" type="primary"  @click="onEdit(data)">编辑</a-button>

                <a-popconfirm title="是否删除此网站?" @confirm="() => onDisable(data)">
                  <a-button size="small" type="danger">删除</a-button type="primary" >
                </a-popconfirm>

                <a-button size="small" type="primary"  @click="onEditList(data)">编辑记录</a-button>
                <a-button size="small" type="primary"  @click="paymentView(data)">查看支付</a-button>
                <a-button size="small" type="primary"  @click="dmcaView(data)">查看DMCA</a-button>
                <a-button size="small" type="primary"  @click="brandView(data)">品牌日志</a-button>
              </span>

        </template>
      </a-table>
    </div>
  <!-- 设置邮箱模态框 -->
  <a-modal

      title="设置邮箱"
      :visible="setUpModalVisible"
      :confirm-loading="setUpLoading"
      @ok="saveSetUp"
      @cancel="cancelSetUp"
    >
      <!-- 编辑 -->
      <a-form-model
        ref="setUpRef"
        :model="setUpForm"
        :rules="setUpFormRules"
        :label-col="{span:7}"
        :wrapper-col="{span:17}"
      >


        <a-form-model-item label="类型" prop="mail_mailer">
          <a-select v-model="setUpForm.mail_mailer" placeholder="请选择类型..." >
              <a-select-option value="smtp">smtp</a-select-option>
              <a-select-option value="mailgun">mailgun</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="主机" prop="mail_host">
          <a-input v-model="setUpForm.mail_host" placeholder="请输入主机..."/>
        </a-form-model-item>

          <a-form-model-item label="端口" prop="mail_port">
            <a-input v-model="setUpForm.mail_port" placeholder="请输入端口..."/>
          </a-form-model-item>
          <a-form-model-item label="用户名" prop="mail_username">
            <a-input v-model="setUpForm.mail_username" placeholder="请输入用户名..."/>
          </a-form-model-item>
          <a-form-model-item label="密码" prop="mail_password">
            <a-input v-model="setUpForm.mail_password" placeholder="请输入密码..."/>
          </a-form-model-item>
      </a-form-model>
  </a-modal>

    <!-- 设置支付模态框 -->
    <a-modal

    title="设置支付"
    :visible="setPaymentModalVisible"
    :confirm-loading="setPaymentLoading"
    @ok="saveSetPayment"
    @cancel="cancelSetPayment"
    width="760px"
  >
    <!-- 编辑 -->
    <a-form-model
      ref="setPaymentRef"
      :model="setPaymentForm"
      :label-col="{xs:{span:24},sm:{span:8}}"
      :wrapper-col="{xs:{span:24},sm:{span:16}}"
      class="setPaymentModel"
    >
    <a-card title="Bank">
      <a-col :xs="24" :sm="12">
        <a-form-model-item label="最小金额" class="setPaymentItem" prop='bank.min_amount'>
          <a-input-number  v-model="setPaymentForm.bank.min_amount" placeholder="请输入最小金额..."/>
        </a-form-model-item>
      </a-col>
      <a-col :xs="24" :sm="12">
        <a-form-model-item label="最大金额" class="setPaymentItem" prop='bank.max_amount'>
          <a-input-number v-model="setPaymentForm.bank.max_amount" placeholder="请输入最大金额..."/>
        </a-form-model-item>
      </a-col>
      <a-col :xs="24" :sm="12">
        <a-form-model-item label="是否收以下国家"  prop='bank.bw_switch'>
          <a-select v-model="setPaymentForm.bank.bw_switch" placeholder="请选择类型..." >
            <a-select-option value="1">不收以下国家</a-select-option>
            <a-select-option value="0">仅收以下国家</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :xs="24" :sm="12">
      <a-form-model-item label="国家名称" prop='bank.bw_list'>
        <a-input v-model="setPaymentForm.bank.bw_list" placeholder="请输入国家ISO2代码,用逗号分开"/>
      </a-form-model-item>
    </a-col>
    </a-card>

    <a-card title="Mpay">
      <a-col :xs="24" :sm="12">
      <a-form-model-item label="最小金额" class="setPaymentItem" prop='mpay.min_amount'>
        <a-input-number v-model="setPaymentForm.mpay.min_amount" placeholder="请输入最小金额..."/>
      </a-form-model-item>
    </a-col>
    <a-col :xs="24" :sm="12">
      <a-form-model-item label="最大金额" class="setPaymentItem" prop='mpay.max_amount'>
        <a-input-number v-model="setPaymentForm.mpay.max_amount" placeholder="请输入最大金额..."/>
      </a-form-model-item>
    </a-col>
      <a-col :xs="24" :sm="12">
        <a-form-model-item label="是否收以下国家"  prop='stripe.bw_switch'>
          <a-select v-model="setPaymentForm.mpay.bw_switch" placeholder="请选择类型..." >
            <a-select-option value="1">不收以下国家</a-select-option>
            <a-select-option value="0">仅收以下国家</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :xs="24" :sm="12">
        <a-form-model-item label="国家名称" prop='stripe.bw_list'>
          <a-input v-model="setPaymentForm.mpay.bw_list" placeholder="请输入国家ISO2代码,用逗号分开"/>
        </a-form-model-item>
      </a-col>
    </a-card>

    <a-card title="Stripe">
      <a-col :xs="24" :sm="12">
      <a-form-model-item label="最小金额" class="setPaymentItem" prop='stripe.min_amount'>
        <a-input-number v-model="setPaymentForm.stripe.min_amount" placeholder="请输入最小金额..."/>
      </a-form-model-item>
    </a-col>
      <a-col :xs="24" :sm="12">
      <a-form-model-item label="最大金额" class="setPaymentItem" prop='stripe.max_amount'>
        <a-input-number v-model="setPaymentForm.stripe.max_amount" placeholder="请输入最大金额..."/>
      </a-form-model-item>
    </a-col>
      <a-col :xs="24" :sm="12">
      <a-form-model-item label="是否收以下国家"  prop='stripe.bw_switch'>
        <a-select v-model="setPaymentForm.stripe.bw_switch" placeholder="请选择类型..." >
            <a-select-option value="1">不收以下国家</a-select-option>
            <a-select-option value="0">仅收以下国家</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-col>
      <a-col :xs="24" :sm="12">
      <a-form-model-item label="国家名称" prop='stripe.bw_list'>
        <a-input v-model="setPaymentForm.stripe.bw_list" placeholder="请输入国家ISO2代码,用逗号分开"/>
      </a-form-model-item>
    </a-col>
      <a-col :xs="24" :sm="12">
      <a-form-model-item label="转化金额" class="setPaymentItem">
        <a-input-number v-model="conversion_amount" placeholder="请输入转化金额..."/>
      </a-form-model-item>
    </a-col>
    </a-card>

    <a-card title="PP">
      <a-col :xs="24" :sm="12">
      <a-form-model-item label="最小金额" class="setPaymentItem" prop='pp.min_amount'>
        <a-input-number v-model="setPaymentForm.pp.min_amount" placeholder="请输入最小金额..."/>
      </a-form-model-item>
    </a-col>
      <a-col :xs="24" :sm="12">
      <a-form-model-item label="最大金额" class="setPaymentItem" prop='pp.max_amount'>
        <a-input-number v-model="setPaymentForm.pp.max_amount" placeholder="请输入最大金额..."/>
      </a-form-model-item>
    </a-col>
      <a-col :xs="24" :sm="12">
      <a-form-model-item label="是否收以下国家"  prop='pp.bw_switch'>
        <a-select v-model="setPaymentForm.pp.bw_switch" placeholder="请选择类型..." >
            <a-select-option value="1">不收以下国家</a-select-option>
            <a-select-option value="0">仅收以下国家</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-col>
      <a-col :xs="24" :sm="12">
      <a-form-model-item label="国家名称" prop='pp.bw_list'>
        <a-input v-model="setPaymentForm.pp.bw_list" placeholder="请输入国家ISO2代码,用逗号分开"/>
      </a-form-model-item>
    </a-col>
    </a-card>

      <a-card title="VISA">
        <a-col :xs="24" :sm="12">
          <a-form-model-item label="最小金额" class="setPaymentItem" prop='visa.min_amount'>
            <a-input-number v-model="setPaymentForm.visa.min_amount" placeholder="请输入最小金额..."/>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12">
          <a-form-model-item label="最大金额" class="setPaymentItem" prop='visa.max_amount'>
            <a-input-number v-model="setPaymentForm.visa.max_amount" placeholder="请输入最大金额..."/>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12">
          <a-form-model-item label="是否收以下国家"  prop='visa.bw_switch'>
            <a-select v-model="setPaymentForm.visa.bw_switch" placeholder="请选择类型..." :options='bwSwitchList'>
              <a-select-option value="1">不收以下国家</a-select-option>
              <a-select-option value="0">仅收以下国家</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12">
          <a-form-model-item label="国家名称" prop='visa.bw_list'>
            <a-input v-model="setPaymentForm.visa.bw_list" placeholder="请输入国家ISO2代码,用逗号分开"/>
          </a-form-model-item>
        </a-col>


      <a-col :xs="24" :sm="12">
        <a-form-model-item label="转化金额" class="setPaymentItem" >
          <a-input-number v-model="visa_conversion_amount" placeholder="请输入转化金额..."/>
        </a-form-model-item>
      </a-col>
      <a-col :xs="24" :sm="12">
        <a-form-model-item label="折扣率" class="setPaymentItem">
          <a-input-number v-model="visa_discount" placeholder="请输入折扣率..."/>
        </a-form-model-item>
      </a-col>
      </a-card>

    </a-form-model>
</a-modal>
    <!-- 查看支付模态框 -->
    <a-modal

        title="查看支付"
        :visible="paymentVisible"
        :confirm-loading="paymentLoading"
        @ok="paymentPayment"
        @cancel="paymentPayment"
        width="760px"
        cancelText="关闭"
    >
      <!-- 编辑 -->
      <a-form-model
          ref="setPaymentRef"
          :model="paymentForm"
          :label-col="{xs:{span:24},sm:{span:8}}"
          :wrapper-col="{xs:{span:24},sm:{span:16}}"
          class="setPaymentModel"
      >
        <a-spin :spinning="paymentLoading">
        <a-card title="Bank">
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="最小金额" class="setPaymentItem" prop='bank.min_amount'>
              <a-input-number  v-model="paymentForm.bank.min_amount" placeholder="请输入最小金额..."/>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="最大金额" class="setPaymentItem" prop='bank.max_amount'>
              <a-input-number v-model="paymentForm.bank.max_amount" placeholder="请输入最大金额..."/>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="是否收以下国家"  prop='bank.bw_switch'>
              <a-select v-model="paymentForm.bank.bw_switch" placeholder="请选择类型..." :options='bwSwitchList'>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="国家名称" prop='bank.bw_list'>
              <a-input v-model="paymentForm.bank.bw_list" placeholder="请输入国家ISO2代码,用逗号分开"/>
            </a-form-model-item>
          </a-col>
        </a-card>

        <a-card title="Mpay">
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="最小金额" class="setPaymentItem" prop='mpay.min_amount'>
              <a-input-number v-model="paymentForm.mpay.min_amount" placeholder="请输入最小金额..."/>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="最大金额" class="setPaymentItem" prop='mpay.max_amount'>
              <a-input-number v-model="paymentForm.mpay.max_amount" placeholder="请输入最大金额..."/>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="是否收以下国家"  prop='stripe.bw_switch'>
              <a-select v-model="paymentForm.mpay.bw_switch" placeholder="请选择类型..." :options='bwSwitchList'>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="国家名称" prop='stripe.bw_list'>
              <a-input v-model="paymentForm.mpay.bw_list" placeholder="请输入国家ISO2代码,用逗号分开"/>
            </a-form-model-item>
          </a-col>
        </a-card>

        <a-card title="Stripe">
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="最小金额" class="setPaymentItem" prop='stripe.min_amount'>
              <a-input-number v-model="paymentForm.stripe.min_amount" placeholder="请输入最小金额..."/>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="最大金额" class="setPaymentItem" prop='stripe.max_amount'>
              <a-input-number v-model="paymentForm.stripe.max_amount" placeholder="请输入最大金额..."/>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="是否收以下国家"  prop='stripe.bw_switch'>
              <a-select v-model="paymentForm.stripe.bw_switch" placeholder="请选择类型..." :options='bwSwitchList'>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="国家名称" prop='stripe.bw_list'>
              <a-input v-model="paymentForm.stripe.bw_list" placeholder="请输入国家ISO2代码,用逗号分开"/>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="转化金额" class="setPaymentItem">
              <a-input-number v-model="conversion_amount" placeholder="请输入转化金额..."/>
            </a-form-model-item>
          </a-col>
        </a-card>

        <a-card title="PP">
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="最小金额" class="setPaymentItem" prop='pp.min_amount'>
              <a-input-number v-model="paymentForm.pp.min_amount" placeholder="请输入最小金额..."/>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="最大金额" class="setPaymentItem" prop='pp.max_amount'>
              <a-input-number v-model="paymentForm.pp.max_amount" placeholder="请输入最大金额..."/>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="是否收以下国家"  prop='pp.bw_switch'>
              <a-select v-model="paymentForm.pp.bw_switch" placeholder="请选择类型..." :options='bwSwitchList'>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item label="国家名称" prop='pp.bw_list'>
              <a-input v-model="paymentForm.pp.bw_list" placeholder="请输入国家ISO2代码,用逗号分开"/>
            </a-form-model-item>
          </a-col>
        </a-card>

          <a-card title="VISA">
            <a-col :xs="24" :sm="12">
              <a-form-model-item label="最小金额" class="setPaymentItem" prop='visa.min_amount'>
                <a-input-number v-model="paymentForm.visa.min_amount" placeholder="请输入最小金额..."/>
              </a-form-model-item>
            </a-col>
            <a-col :xs="24" :sm="12">
              <a-form-model-item label="最大金额" class="setPaymentItem" prop='visa.max_amount'>
                <a-input-number v-model="paymentForm.visa.max_amount" placeholder="请输入最大金额..."/>
              </a-form-model-item>
            </a-col>
            <a-col :xs="24" :sm="12">
              <a-form-model-item label="是否收以下国家"  prop='visa.bw_switch'>
                <a-select v-model="paymentForm.visa.bw_switch" placeholder="请选择类型..." :options='bwSwitchList'>
                  <a-select-option value="1">不收以下国家</a-select-option>
                  <a-select-option value="0">仅收以下国家</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xs="24" :sm="12">
              <a-form-model-item label="国家名称" prop='visa.bw_list'>
                <a-input v-model="paymentForm.visa.bw_list" placeholder="请输入国家ISO2代码,用逗号分开"/>
              </a-form-model-item>
            </a-col>


            <a-col :xs="24" :sm="12">
              <a-form-model-item label="转化金额" class="setPaymentItem" >
                <a-input-number v-model="visa_conversion_amount" placeholder="请输入转化金额..."/>
              </a-form-model-item>
            </a-col>
            <a-col :xs="24" :sm="12">
              <a-form-model-item label="折扣率" class="setPaymentItem" >
                <a-input-number v-model="visa_discount" placeholder="请输入折扣率..."/>
              </a-form-model-item>
            </a-col>
          </a-card>
        </a-spin>
      </a-form-model>
      <template slot="footer">
        <a-button @click="paymentPayment">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
        title="查看DMCA黑名单"
        :visible="dmcaVisible"
        :confirm-loading="dmcaLoading"
        @ok="dmcaClose"
        @cancel="dmcaClose"
        width="760px"
        cancelText="关闭"
    >
      <!-- 编辑 -->
      <a-form-model
          ref="dmcaRef"
          :model="dmcaForm"
          :label-col="{xs:{span:24},sm:{span:8}}"
          :wrapper-col="{xs:{span:24},sm:{span:16}}"
          class="setdmcaModel"
      >
        <a-spin :spinning="dmcaLoading">
        <a-card title="DMCA">
          <a-col :xs="24" :sm="24">
            <a-form-model-item label="DMCA开关"  prop='dmca_switch' labelAlign="left">
              <a-select v-model="dmcaForm.dmca_switch" placeholder="请选择...">
                <a-select-option value="1">开启</a-select-option>
                <a-select-option value="0">关闭</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="24">
            <a-form-model-item label="IP黑名单列表" prop='ip_list'  labelAlign="left">
              <a-list size="large" bordered :data-source="dmcaForm.ip_list">
                <a-list-item slot="renderItem" slot-scope="item, index">
                  {{ item }}
                </a-list-item>
              </a-list>
            </a-form-model-item>
          </a-col>
        </a-card>        
        </a-spin>
      </a-form-model>
      <template slot="footer">
        <a-button @click="dmcaClose">关闭</a-button>
      </template>
    </a-modal>
<!--设置物流-->
    <a-modal

        title="设置物流"
        :visible="setFeeModalVisible"
        :confirm-loading="setFeeLoading"
        @ok="saveSetFee"
        @cancel="cancelSetFee"
        width="800px"
    >
      <!-- 编辑 -->
      <a-form-model
          ref="setFeeRef"
          :model="setFeeForm"
          :rules="setFeeFormRules"
          :label-col="{span:7}"
          :wrapper-col="{span:17}"
      >
        <a-form-model-item label="免运费门槛" prop="mail_host">
          <a-input v-model="setFeeForm.free_shipping_over_amount" placeholder="请输入免运费门槛..."/>
        </a-form-model-item>
        <a-form-model-item label="免运费国家" prop="mail_host">
          <a-input v-model="setFeeForm.free_shipping_country" placeholder="请输入免运费国家代码，多个国家以英文逗号隔开..."/>
        </a-form-model-item>
        <a-form-model-item label="运费" prop="mail_port">
          <a-input v-model="setFeeForm.shipping_fee" placeholder="请输入运费..."/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
        title="设置DMCA"
        :visible="setDmcaModalVisible"
        :confirm-loading="setDmcaLoading"
        @ok="saveSetDmca"
        @cancel="cancelSetDmca"
        width="800px"
    >
      <!-- 编辑 -->
      <a-form-model
          ref="setDmcaRef"
          :rules="setDmcaRules"
          :model="setDmcaForm"
          :label-col="{span:7}"
          :wrapper-col="{span:17}"
      >
        <a-form-model-item label="是否开启DMCA黑名单限制访问"  prop='dmca_switch'>
          <a-select v-model="setDmcaForm.dmca_switch" placeholder="请选择..." >
              <a-select-option value="1">开启</a-select-option>
              <a-select-option value="0">关闭</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

<!--  设置广告语  -->

    <a-modal
        title="设置广告语"
        :visible="setADModalVisible"
        :confirm-loading="setADLoading"
        @ok="saveSetAD"
        @cancel="cancelSetAD"
        width="800px"
    >
      <!-- 编辑 -->
      <a-form-model
          ref="setADRef"
          :model="setADForm"
          :rules="setADFormRules"
          :label-col="{span:7}"
          :wrapper-col="{span:17}"
      >
        <a-form-model-item label="头部广告语" prop="advertising">
          <a-input v-model="setADForm.advertising" placeholder="请输入头部广告语..."/>
        </a-form-model-item>
        <a-form-model-item label="产品页广告语" prop="product_ad">
          <a-input v-model="setADForm.product_ad" placeholder="请输入产品页广告语..."/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
        title="设置品牌"
        :visible="setBrandModalVisible"
        :confirm-loading="setBrandLoading"
        @ok="saveSetBrand"
        @cancel="cancelSetBrand"
        width="800px"
    >
      <!-- 编辑 -->
      <a-form-model
          ref="setBrandRef"
          :model="setBrandForm"
          :label-col="{span:7}"
          :wrapper-col="{span:17}"
      >
        <a-form-model-item label="是否开启品牌限制"  prop='brand_switch'>
          <a-select v-model="setBrandForm.brand_switch" placeholder="请选择..." >
              <a-select-option value="1">开启</a-select-option>
              <a-select-option value="0">关闭</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="B站域名" prop="brand_site_domain">
          <a-input v-model="setBrandForm.brand_site_domain" placeholder="请输入B站域名..."/>
        </a-form-model-item>
        <a-form-model-item label="品牌词列表" prop="brand_list">
          <a-textarea v-model="setBrandForm.brand_list" placeholder="请输入品牌词列表..." :row="20"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>


<!-- 设置图片模态框 -->
<a-modal

title="设置图片"
:visible="setImgModalVisible"
:confirm-loading="setImgLoading"
@ok="saveSetImg"
@cancel="cancelSetImg"
width="800px"
>
<!-- 编辑 -->
<a-form-model
  ref="setImgRef"
  :model="setImgForm"
  :label-col="{span:5}"
  :wrapper-col="{span:19}"

>
<!-- 做到这，刚改完布局 -->
    <a-form-model-item label="banner"  prop='banner'>
      <a-button type="primary" @click="selectBanner">
        选择图片
      </a-button>
      <div class="uploadBox" >
        <div class="uploadItem" v-for="(item) in setImgForm.banner">
          <img :src="item" />
          <a-icon type="close" @click='closeBanner(item)'/>
        </div>
      </div>
    </a-form-model-item>

    <a-form-model-item label="logo" prop='logo'>
      <a-button type="primary" @click="selectLogo">
        选择图片
      </a-button>
      <div class="uploadBox" >
        <div class="uploadItem" v-if="setImgForm.logo">
          <img :src="setImgForm.logo"/>
        </div>
      </div>
    </a-form-model-item>

    <a-form-model-item label="icon" prop='icon'>
      <a-button type="primary" @click="selectIcon">
        选择图片
      </a-button>
      <div class="uploadBox">
        <div class="uploadItem" v-if="setImgForm.icon">
          <img :src="setImgForm.icon"/>
        </div>
      </div>
    </a-form-model-item>




</a-form-model>
</a-modal>


  <!-- 修改帐号信息模态框 -->
  <a-modal
      :title="title"
      :visible="editModalVisible"
      :confirm-loading="confirmLoading"
      @ok="save"
      @cancel="cancel"
    >
      <!-- 编辑 -->
      <a-form-model
        ref="editRef"
        :model="editForm"
        :rules="editFormRules"
        :label-col="{span:7}"
        :wrapper-col="{span:17}"
      >
      <a-form-model-item label="域名" prop="domain">
        <a-input v-model="editForm.domain" placeholder="请输入域名..."/>
      </a-form-model-item>
        <a-form-model-item label="网站标题" prop="title">
          <a-input v-model="editForm.title" placeholder="请输入网站标题..."/>
        </a-form-model-item>

        <a-form-model-item label="店铺名" prop="store_name">
          <a-input v-model="editForm.store_name" placeholder="请输入店铺名..."/>
        </a-form-model-item>

        <a-form-model-item label="店铺所有者" prop="store_owner">
          <a-input v-model="editForm.store_owner" placeholder="请输入店铺所有者..."/>
        </a-form-model-item>

        <a-form-model-item label="店铺地址" prop="store_address">
          <a-input v-model="editForm.store_address" placeholder="请输入店铺地址..."/>
        </a-form-model-item>

        <a-form-model-item label="货币格式" prop="shop_price_is_prefix">
          <a-select v-model="editForm.shop_price_is_prefix" placeholder="请选择货币格式..." >
            <a-select-option  value="true">
             前缀
            </a-select-option>
            <a-select-option  value="false">
              后缀
             </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="免运费门槛" prop="free_shipping_over_amount">
          <a-input v-model="editForm.free_shipping_over_amount" placeholder="请输入免运费门槛..."/>
        </a-form-model-item>
        <a-form-model-item label="免运费国家" prop="free_shipping_country">
          <a-input v-model="editForm.free_shipping_country" placeholder="请输入免运国家..."/>
        </a-form-model-item>

        <a-form-model-item label="运费" prop="shipping_fee">
          <a-input v-model="editForm.shipping_fee" placeholder="请输入运费..."/>
        </a-form-model-item>

        <a-form-model-item label="模版名称" prop="template_id">
          <a-select v-model="editForm.template_id" placeholder="请选择模版..." >
            <a-select-option v-for='(item,index) in templateList' :value="item.value" :key='item.value'>
              {{item.label}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="服务器名称" prop="server_id">
          <a-select v-model="editForm.server_id" placeholder="请选择服务器..." :options='serversList'>

          </a-select>
        </a-form-model-item>

        <a-form-model-item label="ES主机" prop="es_hosts_id">
          <a-select v-model="editForm.es_hosts_id" placeholder="请选择主机..." :options='es_hostList'>

          </a-select>
        </a-form-model-item>
        <a-form-model-item label="目录" prop="es_category_id">
          <a-select v-model="editForm.es_category_id" placeholder="请选择目录..." :options='contentsList'>

          </a-select>
        </a-form-model-item>
        <a-form-model-item label="不统计流量国家">
          <a-input v-model="editForm.statistic_exclude_countries" placeholder="请输入不统计流量国家..."/>
        </a-form-model-item>
      </a-form-model>
  </a-modal>


  <!-- 修改帐号信息模态框 -->
  <a-modal

      title="修改记录"
      :visible="webEditModalVisible"
      @cancel="closeEditList"
    >
      <a-table
      size="small"
      :columns="editColumns"
      :row-key="record => record.id"
      :data-source="editList"
      :pagination='false'
      bordered
    >
              <!-- 状态单元格 -->
              <template slot="is_delete" slot-scope="data,record">
                <a-tag :color="record.is_delete=='0'?'green':'red'">
                    {{record.is_delete|statusFilter}}
                </a-tag>

                <a-popconfirm title="你确定要删除该条记录吗?" ok-text="确认" cancel-text="取消" @confirm="deleteData(record)" v-if="record.is_delete=='0'">
                  <a-button size="small">删除</a-button>
                </a-popconfirm>
              </template>
    </a-table>

    <template slot="footer">
      <a-button  type="primary"  @click="closeEditList">
        确认
      </a-button>
    </template>
  </a-modal>

  </div>
</template>


<script>

import {getSiteList,editSite,addSite,deleteSite,getDomainHistory,deleteDomainHistory,sitesDelete,setMailConfig,getAllSitesCategories,updateSitesInfo,setPaymentInfo,setSitesImages,setFeeConfig,getPaymentInfo,setDmcaConfig,getBlackList,setADConfig,setBrandConfig} from "@/axios/web.js"
import {getAllLanguagesList} from "@/axios/languages.js"
import {getAllGroupList} from "@/axios/group.js"
import {getAllTemplatesList} from "@/axios/templates.js"
import {getAllESHostsList} from "@/axios/es_host.js"
import {getAllServersList} from "@/axios/servers.js"

import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';



const editColumns=[
  {
    align:'center',
    title: 'ID',
    dataIndex: 'id',
  },
  {
    align:'center',
    title: '域名',
    dataIndex: 'domain',
  },
  {
    align:'center',
    title: '状态',
    dataIndex: 'is_delete',
    scopedSlots: { customRender: 'is_delete', },
  },
]
const columns = [//列描述
  {
    align:'center',
    title: '网站ID',
    dataIndex: 'sites_id',
  },
  // {
  //   align:'center',
  //   title: '网站标题',
  //   dataIndex: 'title',
  // },
  {
    align:'center',
    title: '域名',
    dataIndex: 'domain',
  },
  {
    align:'center',
    title: '语言',
    dataIndex: 'language_name',
  },
  {
    align:'center',
    title: '服务器名称',
    dataIndex: 'server_name',
  },
  // {
  //   align:'center',
  //   title: '服务器IP',
  //   dataIndex: 'ip',
  // },
  {
    align:'center',
    title: '模版名称',
    dataIndex: 'template_name',
  },

  {
    align:'center',
    title: 'ES主机名称',
    dataIndex: 'es_hosts_name',
  },
  // {
  //   align:'center',
  //   title: 'ES主机',
  //   dataIndex: 'es_hosts',
  // },
  // {
  //   align:'center',
  //   title: 'ES主机索引',
  //   dataIndex: 'index_name',
  // },

  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {
  filters: {

    statusFilter(status) {
      const statusMap = {
        0: '启用',
        1: '禁用',
      }

      return statusMap[status]

    },
  },
  mixins: [myMixin],
  data() {

    return {
      is_delete_edit: [
        {
            value: 0,
            label: '启用'
        },
        {
            value: 1,
            label: '禁用'
        },
      ],
      is_delete: [
        {
            value: -1,
            label: '全部'
        },
        {
            value: 0,
            label: '启用'
        },
        {
            value: 1,
            label: '禁用'
        },
      ],

      form: {
        page_size: 10,
        page:1,
        es_hosts_name: '',
        user:"",
        index_name:'',
        langugae_id:'',
        es_hosts:'',
        // iso2:''
      },

      columns,//列描述
      editColumns,
      confirmLoading:false,//编辑模态框
      editModalVisible:false,
      editForm:{
        template_id:'',
        es_hosts_id:'',
        server_id:'',
        domain:'',
        title:'',
        es_category_id:'',
        store_name:'',
        store_owner:'',
        store_address:'',
        shop_price_is_prefix:true,
        shipping_fee:'',
        free_shipping_over_amount:'',
        free_shipping_country:'',
        statistic_exclude_countries:null,
      },
      editFormRules: {

        template_id: [
          { required: true, message: '请选择模版', trigger: 'change' },
        ],

        es_hosts_id: [
          { required: true, message: '请选择ES主机', trigger: 'change' },
        ],
        // es_category_id: [
        //   { required: true, message: '请选择目录', trigger: 'change' },
        // ],
        server_id: [
          { required: true, message: '请选择服务器名称', trigger: 'change' },
        ],
        domain: [
          { required: true, message: '请输入域名', trigger: 'blur' },
        ],
        title: [
          { required: true, message: '请输入网站标题', trigger: 'blur' },
        ],

      },

      title:'编辑网站',
      groupList:[],
      templateList:[],
      es_hostList:[],
      serversList:[],
      languageList:[],
      contentsList:[],
      contentsAllList:[],
      webEditModalVisible:false,
      editList:[],

      setUpModalVisible:false,
      setUpLoading:false,
      setUpForm:{
        mail_mailer:'',
        mail_host:'',
        mail_port:'465',
        mail_username:'',
        mail_password:'',
      },
      setUpFormRules: {
        mail_mailer: [
          { required: true, message: '请选择类型', trigger: 'blur' },
        ],

        mail_host: [
          { required: true, message: '请输入主机', trigger: 'blur' },
        ],
        mail_port: [
          { required: true, message: '请输入端口', trigger: 'blur' },
        ],
        mail_username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        mail_password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },
      setFeeFormRules: {
        free_shipping_over_amount: [
          { required: true, message: '请输入免运费门槛', trigger: 'blur' },
        ],

        shipping_fee: [
          { required: true, message: '请输入运费', trigger: 'blur' },
        ],
      },
      setPaymentModalVisible:false,
      paymentVisible:false,
      setPaymentLoading:false,
      paymentLoading:false,
      setPaymentForm:{
        bank: {
            min_amount: "",
            max_amount: "",
            bw_switch: "",
            bw_list: "",
        },
        mpay: {
            min_amount: "",
            max_amount: "",
            bw_switch:"",
            bw_list:"",

        },
        stripe: {
            min_amount: "",
            max_amount: "",
            bw_switch: "",
            bw_list: "",
        },
        pp: {
            min_amount: "",
            max_amount: "",
            bw_switch: "",
            bw_list: ""
        },
        visa:{
          min_amount: "",
          max_amount: "",
          bw_switch: "",
          bw_list: ""
        }
      },
      paymentForm:{
        bank: {
          min_amount: "",
          max_amount: "",
          bw_switch: "",
          bw_list: "",
        },
        mpay: {
          min_amount: "",
          max_amount: "",
          bw_switch: "",
          bw_list: ""
        },
        stripe: {
          min_amount: "",
          max_amount: "",
          bw_switch: "",
          bw_list: "",
        },
        pp: {
          min_amount: "",
          max_amount: "",
          bw_switch: "",
          bw_list: ""
        },
        visa:{
          min_amount: "",
          max_amount: "",
          bw_switch: "",
          bw_list: ""
        }
      },
      dmcaVisible:false,
      dmcaLoading:false,
      dmcaForm:{
        dmca_switch: "",
        ip_list: [],
      },

      ADVisible:false,
      ADLoading:false,
      setADModalVisible:false,
      setADLoading:false,
      setADForm:{
        advertising:'',
        product_ad:''
      },
      setADFormRules:{
        advertising:[
          { required: true, message: '请输入广告语', trigger: 'blur' },
        ],
        product_ad:[
          { required: true, message: '请输入产品页广告语', trigger: 'blur' },
        ]
      },
      setBrandModalVisible:false,
      setBrandLoading:false,
      setBrandForm:{
        brand_site_domain:'',
        brand_switch:'',
        brand_list:''
      },
      conversion_amount: "",
      visa_conversion_amount:"",
      visa_discount:"",
      setImgModalVisible:false,      
      setImgLoading:false,
      setImgForm:{
        banner:[],
        logo:'',
        icon:'',
      },      
      
      setFeeModalVisible:false,
      setFeeLoading:false,
      setFeeForm:{
        free_shipping_over_amount:'',
        free_shipping_country:'',
        shipping_fee:'',
      },
      
      setDmcaModalVisible:false,
      setDmcaLoading:false,
      setDmcaRules:{
        dmca_switch:[{ required: true, message: '请选择是否开启', trigger: 'blur' },]
      },
      setDmcaForm:{
        dmca_switch:'',
      },
      CKFinderStyle:{
        width:700,
        height:500,
      },
      bwSwitchList:[
          {
            value:1,
            label:"不收以下国家",
          },
        {
          value:0,
          label:"仅收以下国家",
        },
      ],
    }    
  },
  mounted() {
    let that=this
    this.fetch();

    let script1=document.createElement("script");
    script1.setAttribute("src", "/ckfinder/sf.js");
    let script2=document.createElement("script");
    script2.setAttribute("src", "/ckfinder/tree-a.js");
    let script3=document.createElement("script");
    script3.setAttribute("src", "/ckfinder/ckfinder.js");
    document.body.appendChild(script1);
    document.body.appendChild(script2);
    document.body.appendChild(script3);


    getAllLanguagesList().then((data)=>{
      that.languageList=data.data.map((item)=>{
        return{
          value:item.language_id,
          label:item.language_name,
          iso3:item.iso3
        }
      })
      console.log(that.languageList,'语言列表')
    });
//   search(){//搜索
//         console.log(languageId)

//     },
    // getAllGroupList().then((data)=>{
    //   that.groupList=data.data.map((item)=>{
    //     return{
    //       value:item.group_id,
    //       label:item.group_name
    //     }
    //   })
    //   console.log(that.groupList,'分组列表')
    // });

    getAllTemplatesList().then((data)=>{
      that.templateList=data.data.map((item)=>{
        return{
          value:item.template_id,
          label:item.template_name,
          iso2:item.iso2
        }
      })
      console.log(that.templateList,'模板列表')
    });

    getAllESHostsList().then((data)=>{
      that.es_hostList=data.data.map((item)=>{
        return{
          value:item.es_hosts_id,
          label:item.es_hosts_name
        }
      })
      console.log(that.es_hostList,'ES列表')
    });

    getAllServersList().then((data)=>{
      that.serversList=data.data.map((item)=>{
        return{
          value:item.server_id,
          label:item.server_name
        }
      })
      console.log(that.serversList,'服务器列表')
    });
    getAllSitesCategories().then((data)=>{
      that.contentsAllList=data.data.map((item)=>{
        return{
          value:item.id,
          label:item.category_mark,
          es_id:item.es_id,
        }
      })
      console.log(that.contentsAllList,'目录列表')
    });
  },
  methods:{

      async fetch (params = {}) {
        this.loading = true;
        let data=await getSiteList({...params,...this.form})
        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = data.data.total;
        this.data = data.data.list;
        this.pagination = pagination;
        this.setBrandForm.brand_list = data.data.brand_list;
      },

      //添加用户数据
      onAdd(){
        this.editModalVisible=true
        this.title='添加网站'
      },

      //禁用ES
      async onDisable(data){
        let resule=await sitesDelete({sites_id:data.sites_id});
          if(resule.status==1){
            this.$message.success(resule.errmsg);
            this.fetch()
          }else{
            this.$message.error(resule.errmsg);
        }
      },
    //查看支付
    paymentView(data){
      this.paymentLoading = true;
      this.paymentVisible = true;
      getPaymentInfo({sites_id: data.sites_id}).then((res)=>{
        var responseData = res.data.data;
        var bankData = responseData.list;
        this.conversion_amount = responseData.conversion_amount;
        this.paymentForm.bank = bankData[bankData.findIndex(item => item.name === 'bank')];
        this.paymentForm.pp = bankData[bankData.findIndex(item => item.name === 'pp')];
        this.paymentForm.mpay = bankData[bankData.findIndex(item => item.name === 'mpay')];
        this.paymentForm.stripe = bankData[bankData.findIndex(item => item.name === 'stripe')];
        this.paymentForm.visa = bankData[bankData.findIndex(item => item.name === 'visa')];
        this.visa_conversion_amount = responseData.visa_conversion_amount;
        this.visa_discount = responseData.visa_discount;
        this.paymentLoading = false;
        console.log(this.setPaymentForm,'查看')
      })


    },
    dmcaView(data){
      this.dmcaLoading = true;
      this.dmcaVisible = true;
      getBlackList({sites_id: data.sites_id}).then((res)=>{
        var data = res.data.data;
        this.dmcaForm.dmca_switch = data.dmca_switch;
        this.dmcaForm.ip_list = data.ip_list;
        this.dmcaLoading = false;
        console.log(this.dmcaForm,'查看')
      })
    },
    brandView(data) {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1); // Subtract 1 day
    
        const year = yesterday.getFullYear();
        let month = (yesterday.getMonth() + 1).toString().padStart(2, '0'); // Pad month with leading zero
        let day = yesterday.getDate().toString().padStart(2, '0');
        window.open("https://" + data.domain + "/api/getLog?date=" + year + month + day, '_blank');
    },
    dmcaClose() {
      this.dmcaVisible = false;
    },
    paymentPayment(){
      this.paymentVisible = false;
      this.visa_discount = this.visa_conversion_amount = '';
    },

      //获取操作记录
      onEditList(data){
        this.editList=[]
        getDomainHistory({site_id:data.sites_id}).then((res)=>{
          if(res.status==1){
            if (JSON.stringify(res.data) !== '{}') {
              　　this.editList=res.data
            }
            this.webEditModalVisible=true
          }
        })
      },
      closeEditList(){
        this.webEditModalVisible=false
      },
      deleteData(data){
        console.log(data,'data')
        deleteDomainHistory({history_id:data.id}).then((res)=>{
          console.log(res)
          if(res.status==1){
            data.is_delete=1
            this.$message.success(res.errmsg);
          }
        })
      },
      //编辑保存用户数据
      onEdit(data){
        this.title='编辑网站'
        this.editModalVisible=true
        this.$nextTick(()=>{
          this.editForm={...data}
          this.contentsList=this.contentsAllList.filter((item)=>item.es_id==data.es_hosts_id)
          if(this.contentsList.length==0)this.$message.error('该主机下没有目录');
        })
        console.log(this.editForm,'data')

      },
      save(){
        this.$refs.editRef.validate( async valid => {
        if (valid) {
          this.confirmLoading = true;
          let resule
          if(this.title=='添加网站'){

            const {id,...rest} = this.editForm;
            resule=await addSite({...rest});
          }else{
            resule=await editSite({...this.editForm});
          }

          this.confirmLoading = false;
          if(resule.status==1){
            console.log(resule)
            this.$message.success(resule.errmsg);
            this.editModalVisible = false;
            this.$refs.editRef.resetFields();
            this.fetch();
          }else{
            this.$message.error(resule.errmsg);
          }

        } else {
          return false;
        }
      });


      },
      cancel(){
        this.$refs.editRef.resetFields();
        this.editModalVisible = false;
      },
      //设置邮箱
      onSetUp(){
        this.setUpModalVisible=true
      },
      saveSetUp(){
        this.$refs.setUpRef.validate( async valid => {
        if (valid) {
          this.setUpLoading = true;
          console.log(this.setUpForm)
          setMailConfig({...this.setUpForm,sites_id:this.selectedRowKeys.join(',')}).then((resule)=>{
            this.setUpLoading = false;
            if(resule.status==1){
              console.log(resule)
              this.$message.success(resule.errmsg);
              this.setUpModalVisible = false;
              this.$refs.setUpRef.resetFields();
            }else{
              this.$message.error(resule.errmsg);
            }
          });
        } else {
          return false;
        }
      });

      },
      cancelSetUp(){
        this.$refs.setUpRef.resetFields();
        this.setUpModalVisible = false;
      },
      //更新网站
      updataWeb(){
        updateSitesInfo({sites_id:this.selectedRowKeys.join(',')}).then((resule)=>{
            if(resule.status==1){
              console.log(resule)
              this.$message.success(resule.errmsg);
              this.setUpModalVisible = false;
              this.$refs.setUpRef.resetFields();
            }else{
              this.$message.error(resule.errmsg);
            }
          });
      },

      //设置付款方式
      setPayment(){
        this.setPaymentModalVisible=true
      },
      saveSetPayment(){
        this.setPaymentLoading = true;

          setPaymentInfo({payment_data:this.setPaymentForm,sites_id:this.selectedRowKeys.join(','),conversion_amount:this.conversion_amount,visa_conversion_amount:this.visa_conversion_amount,visa_discount:this.visa_discount}).then((resule)=>{
            this.setPaymentLoading = false;
            if(resule.status==1){

              this.$message.success(resule.errmsg);
              this.setPaymentLoading = false;
              this.$refs.setPaymentRef.resetFields();
              console.log(this.setPaymentForm,'setPaymentForm')
              this.setPaymentModalVisible = false;
            }else{
              this.$message.error(resule.errmsg);
            }
            this.visa_discount = this.visa_conversion_amount = this.conversion_amount = '';
          });

        this.visa_discount = this.visa_conversion_amount = this.conversion_amount = '';
      },
      cancelSetPayment(){
        this.visa_discount = this.visa_conversion_amount = this.conversion_amount = '';
        this.$refs.setPaymentRef.resetFields();
        this.setPaymentModalVisible = false;
        console.log(this.setPaymentForm,'setPaymentForm')
      },
    //设置物流
      setFee(){
        this.setFeeModalVisible=true
      },
      saveSetFee(){
        this.setFeeLoading = true;
        console.log(this.setFeeForm)
        setFeeConfig({...this.setFeeForm,sites_id:this.selectedRowKeys.join(',')}).then((resule)=>{
          this.setFeeLoading = false;
          console.log(resule)
          if(resule.status==1){
            console.log(resule)
            this.$message.success(resule.errmsg);
            this.setFeeLoading = false;
            this.$refs.setFeeRef.resetFields();
            console.log(this.setFeeForm,'setImgForm')
            this.setFeeModalVisible = false;
          }else{
            this.$message.error(resule.errmsg);
          }
        });
      },
    cancelSetFee(){
        this.$refs.setFeeRef.resetFields();
        this.setFeeModalVisible = false;
      },
    // 设置DMCA
      setDmca(){
        this.setDmcaModalVisible=true
      },
      saveSetDmca(){
        this.$refs.setDmcaRef.validate(async valid =>{
          if (valid)
          {
            this.setDmcaLoading = true;
            console.log(this.setDmcaForm)
            setDmcaConfig({...this.setDmcaForm,sites_id:this.selectedRowKeys.join(',')}).then((resule)=>{
              this.setDmcaLoading = false;
              console.log(resule)
              if(resule.status==1){
                console.log(resule)
                this.$message.success(resule.errmsg);
                this.setDmcaLoading = false;
                this.$refs.setDmcaRef.resetFields();
                this.setDmcaModalVisible = false;
              }
            });
          }else{
            return false;
          }
        });
      },
      cancelSetDmca(){
        this.$refs.setDmcaRef.resetFields();
        this.setDmcaModalVisible = false;
      },
    // 设置广告语
    setAD(){
      this.setADModalVisible=true
    },
    saveSetAD(){
      this.$refs.setADRef.validate( async valid => {
        if (valid) {
          this.setADLoading = true;
          setADConfig({...this.setADForm,sites_id:this.selectedRowKeys.join(',')}).then((resule)=>{
            this.setADLoading = false;
            console.log(resule)
            if(resule.status==1){
              console.log(resule)
              this.$message.success(resule.errmsg);
              this.setADLoading = false;
              this.$refs.setADRef.resetFields();
              this.setADModalVisible = false;
            }
          });

        } else {
          return false;
        }
      });

    },
    cancelSetAD(){
      this.$refs.setADRef.resetFields();
      this.setADModalVisible = false;
    },
    setBrand(){
      this.setBrandModalVisible=true;
    },
    saveSetBrand(){
      this.setBrandLoading = true;
      setBrandConfig({...this.setBrandForm,sites_id:this.selectedRowKeys.join(',')}).then((resule)=>{
        this.setBrandLoading = false;
        if(resule.status==1){
          console.log(resule)
          this.$message.success(resule.errmsg);
          this.setBrandLoading = false;
          this.$refs.setBrandRef.resetFields();
          this.setBrandModalVisible = false;
        }else{
          this.$message.error(resule.errmsg);
        }
      });
    },
    cancelSetBrand(){
      this.$refs.setBrandRef.resetFields();
      this.setBrandModalVisible = false;
    },
      //设置图片
      setImg(){
        this.setImgModalVisible=true
      },
      saveSetImg(){
        this.setImgLoading = true;
          console.log(this.setImgForm)
          setSitesImages({images_data:this.setImgForm,sites_id:this.selectedRowKeys.join(',')}).then((resule)=>{
            this.setImgLoading = false;
            console.log(resule)
            if(resule.status==1){
              console.log(resule)
              this.$message.success(resule.errmsg);
              this.setImgLoading = false;
              this.$refs.setImgRef.resetFields();
              console.log(this.setImgForm,'setImgForm')
              this.setImgModalVisible = false;
            }else{
              this.$message.error(resule.errmsg);
            }
          });
      },
      cancelSetImg(){
        this.$refs.setImgRef.resetFields();
        this.setImgModalVisible = false;
        console.log(this.setImgForm,'setPaymentForm')
      },
      selectBanner(){
        let that=this
        function S(e) {
        for (var t = "", n = e.charCodeAt(0), i = 1; i < e.length; ++i)
            t += String.fromCharCode(e.charCodeAt(i) ^ i + n & 127);
        return t
    }
    S('7[R\\\x16QRZ^,l , <')
        CKFinder.modal( {
          chooseFiles: true,
          width: this.CKFinderStyle.width,
          height: this.CKFinderStyle.height,
          language: 'zh-cn',
          startupPath:'轮播图:/',
          rememberLastFolder:false,
          onInit: function( finder ) {
              finder.on( 'files:choose', function( evt ) {
                  var files = evt.data.files;
                  files.models.map((item)=>{
                    let a=that.setImgForm.banner.includes(item.changed.url)
                    that.setImgForm.banner.includes(item.changed.url)==false&&that.setImgForm.banner.push(item.changed.url)
                  })
              } );
          }
        });
      },
      selectLogo(){
        let that=this
        CKFinder.modal( {
          chooseFiles: true,
          width: this.CKFinderStyle.width,
          height: this.CKFinderStyle.height,
          language: 'zh-cn',
          startupPath:'Logo:/',
          rememberLastFolder:false,
          onInit: function( finder ) {
              finder.on( 'files:choose', function( evt ) {
                  var files = evt.data.files;
                  that.setImgForm.logo=files.models[0].changed.url
              } );
          }
        });
      },
      selectIcon(){
        let that=this
        CKFinder.modal( {
          chooseFiles: true,
          width: this.CKFinderStyle.width,
          height: this.CKFinderStyle.height,
          language: 'zh-cn',
          startupPath:'图标:/',
          rememberLastFolder:false,
          onInit: function( finder ) {
              finder.on( 'files:choose', function( evt ) {
                  var files = evt.data.files;
                  that.setImgForm.icon=files.models[0].changed.url
              } );
          }
        });
      },
      closeBanner(data){
        this.setImgForm.banner=this.setImgForm.banner.filter(item=>item!=data)
      }
    },
    beforeDestroy(){

    },
    watch:{
      'editForm.es_hosts_id':{
        handler: function(value) {
          console.log(value,'value')
          if(value){
            this.contentsList=this.contentsAllList.filter((item)=>item.es_id==value)
            if(this.contentsList.length==0)this.$message.error('该主机下没有目录');
          }

        }
      }
    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
::v-deep .setPaymentModel .ant-form-item{margin-bottom:0;}
::v-deep .setPaymentModel .setPaymentItem .ant-input-number{width:100%}
.ant-modal-body .ant-card{
  margin-bottom:12px;

}
::v-deep .ant-card-head{min-height: 24px;background: #f5f5f5;}
::v-deep .ant-modal-body .ant-card .ant-card-head-title{padding:6px 0}
::v-deep .ant-modal-body .ant-card .ant-card-body{
  padding:12px;
}
.uploadBox{
  display: flex;
  flex-wrap: wrap;
}
.uploadItem{
  width:100px;
  height:100px;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  position: relative;
  margin: 10px 10px 0 0;
}
.uploadItem:hover .anticon{display: block;}
.uploadBox img{
  object-fit: cover;
  width:100%;
  height:100%;
}
.esHostName{
  @media (min-width: 1025px){
    margin-left: 30px;
  }
}
.anticon{
  position: absolute;
  right: 0px;
  top: 0px;
  background: #f5f5f5;
  padding: 4px;
  cursor: pointer;
  display: none;
}
::v-deep .ant-modal-body{
  max-height: 600px;
  overflow: auto;
}
</style>
